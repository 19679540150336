import axios from 'axios';
import API from '../config/config';

const axiosInstance = axios.create({
  baseURL: API.baseUrl,
  timeout: 60000
  // withCredentials: true // set it to true for cookies
});

class HTTPService {
  invokeApi = (request: any, callback: any) => {
    const config: any = {
      method: request.method,
      url: request.url
    };
    if (request.data !== undefined) {
      config.data = request.data;
    }
    if (request.params !== undefined) {
      config.params = request.params;
    }
    if (request.headers !== undefined) {
      config.headers = request.headers;
    } else {
      config.headers = {
        'Content-Type': 'application/json'
      };
    }
    if (request.responseType) {
      config.responseType = request.responseType;
    }
    axiosInstance
      .request(config)
      .then(res => {
        callback(true, res.data);
      })
      .catch(err => {
        callback(false, err ? (err.response ? err.response.data : err) : err);
      });
  };

  invokeApiPromise = (request: any) => {
    const config: any = {
      method: request.method,
      url: request.url
    };
    if (request.data !== undefined) {
      config.data = request.data;
    }
    if (request.params !== undefined) {
      config.params = request.params;
    }
    if (request.headers !== undefined) {
      config.headers = request.headers;
    } else {
      config.headers = {
        'Content-Type': 'application/json'
      };
    }
    if (request.responseType) {
      config.responseType = request.responseType;
    }
    return axiosInstance
      .request(config)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err.response;
      });
  };
}

const httpService = new HTTPService();

export default httpService;
